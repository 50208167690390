import React, { useEffect } from "react";
import styled from "styled-components";
import { useDarkMode } from "../ducks/colourModeSlice";
import { useUpdateHeaderActions } from "../ducks/headerActionsSlice";
import { logout } from "../ducks/loginSlice";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "gatsby";
import CodeCopier from "../components/code-copier";
import ProgressMeter from "../components/progress-meter";
import { H3, Body } from "../components/common/typography/";
import SEO from "../components/common/seo";
import featherBackground from "../assets/images/FeatherBackground.png";

const SharePage = ({ location }) => {
  useDarkMode();
  const dispatch = useDispatch();
  useUpdateHeaderActions({
    left: {
      text: "See Whisky",
      to: "/",
    },
    right: {
      text: "Log Out",
      action: () => dispatch(logout()),
    },
  });

  const { code, email, friendCount } = useSelector((state) => state.login);
  useEffect(() => {
    if (!email) navigate("/");
  }, [email]);

  return (
    <Root>
      <SEO title="Your Unique Code" />
      <Background>
        <Title>A special whisky for an unhurried moment</Title>
        <Instructions>
          <Body>
            Share the below code with friends or family through social media,
            email, text.
          </Body>
          <Body>
            A minimum of four other people must click the link and sign up to
            complete your entry.
          </Body>
          <Body>
            Once you have unlocked your entry, you'll officially have entered
            the competition. We'll announce the winner on 14th December.
          </Body>
          <Body>
            In the meantime, follow Glengoyne on our social media channels to
            keep up to date with the latest news and information.
          </Body>
        </Instructions>
        <CodeCopier code={code} location={location} />
      </Background>
      <ProgressMeter block={false} friendCount={friendCount} />
    </Root>
  );
};

const Root = styled.div`
  padding-top: 146px;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Background = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url(${featherBackground});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    background: none;
    padding: 0 ${({ theme }) => theme.spacing.md};
  }
`;

const Title = styled(H3)`
  max-width: 600px;
  margin-top: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const Instructions = styled.div`
  max-width: 370px;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  ${Body} {
    margin-bottom: ${({ theme }) => theme.spacing.md};
    padding: 0 ${({ theme }) => theme.spacing.sm};
  }
`;

export default SharePage;
