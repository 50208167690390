import React from "react";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { Body } from "../components/common/typography/";
import CTAButton from "../components/common/cta-button";

const CodeCopier = ({ code, location }) => {
  const codeLink = {
    href: `${location.origin}/join/${code}`,
    display: `${location.host}/join/${code}`,
  };
  const [isCopied, setIsCopied] = React.useState(false);
  const copyLink = () => {
    copy(codeLink.href, { format: "text/plain" });
    setIsCopied(true);
  };

  React.useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 5000);
    }
  }, [isCopied]);

  return (
    <Root>
      <DisplayText>{codeLink.display}</DisplayText>
      <CTAButton
        inverted
        text={isCopied ? "Copied" : "Copy Link"}
        action={copyLink}
      />
    </Root>
  );
};

const Root = styled.div`
  margin: ${({ theme }) => theme.spacing.xxl} auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: ${({ theme }) => theme.spacing.md} auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const DisplayText = styled(Body)`
  width: 20rem;
  max-width: 95%;
  border-bottom: 1px solid ${({ theme }) => theme.colour.lightBlack};
  text-align: left;
  padding-bottom: ${({ theme }) => theme.spacing.sm};
  margin-bottom: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    text-align: center;
    padding-bottom: ${({ theme }) => theme.spacing.xxs};
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

export default CodeCopier;
